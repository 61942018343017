.portfolio-home-content {
    text-align: center;
    font-family: Raleway, Helvetica, sans-serif;
    height: 100%;
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
    display: flex;
    justify-content: center;
}

.portfolio-home-content-bowtie {
    height: 200px;
}

.portfolio-home-content-bowtie img {
    height: inherit;
    width: auto;
    object-fit: cover;
}

.portfolio-home-content-headline {
    font-size: 4rem;
    margin-bottom: 1.2rem;
    font-weight: 300;
}

.portfolio-home-content-subline {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 300;
    color: #1a1a1a;
}

.portfolio-home-content-icons a {
    text-decoration: none;
    width: 100px;
    height: 100px;
    margin: 0px 6px;
}

.portfolio-home-content-icons {
    display: flex;
    justify-content: center;
}

.portfolio-icon-link-devpost {
    height: inherit;
}

.portfolio-icon-link-linkedin {
    height: inherit;
}

.portfolio-icon-link-github {
    height: inherit;
}

@media only screen and (max-width: 600px) {
    .portfolio-home-content-headline {
        font-size: 3rem;
        margin-top: 0.5rem;
        margin-bottom: 0.7rem;
        font-weight: 300;
    }

    .portfolio-home-content-subline {
        font-size: 1rem;
        margin-bottom: 0.4rem;
        font-weight: 300;
        color: #1a1a1a;
    }

    .portfolio-home-content-icons a {
        text-decoration: none;
        width: 60px;
        height: 60px;
    }
}
