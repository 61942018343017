/* Style the header with a grey background and some padding */
@import url('https://fonts.googleapis.com/css?family=Raleway');

.portfolio-header {
    overflow: hidden;
    background-color: var(--main-bg);
    justify-content: space-between;
    padding: 10px 5px;
    font-family: 'Raleway', sans-serif;
    flex: 0 0 auto
}

/* Style the header links */
.portfolio-header a {
    float: left;
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.portfolio-header-name {
    font-size: 25px;
    float: left;
    float: left;
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    line-height: 25px;
    border-radius: 4px;
}

/* Change the background color on mouse-over */
.portfolio-header a:hover {
    background-color: #ddd;
    color: black;
}

/* Style the active/current link*/
.portfolio-header a.active {
    background-color: var(--main-color);
    color: white;
}

/* Float the link section to the right */
.portfolio-header-right {
    float: right;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 580px) {
    .portfolio-header-name {
        float: none;
        display: block;
        text-align: left;
    }
    .portfolio-header a {
        float: none;
        display: block;
        text-align: left;
    }
    .portfolio-header-right {
        float: none;
    }
}
