.portfolio-body {
    width: 100%;
    display: flex;
    flex: 1;
    z-index: 1;
    height: 100%;
    overflow-y: scroll;
}

html {
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
