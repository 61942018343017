body {
    --main-color: #551a8b;
    --main-bg: #ffffff30;
}

.App {
    background-image: url('../resources/images/header.jpg');
    background-color: #cccccc;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: fixed;
}

@import url('https://fonts.googleapis.com/css?family=Raleway');

body {
    display: block;
    margin: 0;
    overflow: hidden;
    font-family: 'Raleway', sans-serif;
}

.portfolio-about-content-contact-link {
    position: absolute;
    bottom: 6px;
    left: 6px;
}
