.portfolio-about-content {
    display: flex;
    flex-flow: row nowrap;
    --highlight-color: #d0d0d0;
}

.portfolio-about-content-profile-pic-wrapper {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 1000px;
    justify-content: center;
    padding: 20px;
}

.portfolio-about-content-bio {
    background-color: var(--main-bg);
    border-radius: 10px;
    justify-content: center;
    margin: auto;
    padding: 50px 30px;
    margin-right: 20px;
    font-size: large;
}

.portfolio-about-content-profile-pic {
    width: 100%;
    border-radius: 10px;
}

.portfolio-about-content-resume-button {
    background-color: var(--main-bg);
    border-radius: 10px;
    border: none;
    padding: 10px;
    margin-top: 10px;
}

.portfolio-about-content-bio a {
    color: var(--main-color);
    text-decoration: none;
}

.portfolio-about-content-resume-button:hover,
.portfolio-about-content-resume-button:focus {
    background-color: var(--highlight-color);
    text-decoration: none;
    cursor: pointer;
}
@media screen and (max-width: 700px) {
    .portfolio-about-content {
        flex-flow: column nowrap;
        justify-content: flex-start;
    }

    .portfolio-about-content-profile-pic-wrapper {
        margin: 20px 40px;
    }

    .portfolio-about-content-bio {
        margin: 0px 20px;
    }
}
