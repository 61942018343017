.portfolio-project-modal {
    position: absolute; /* Stay in place */
    display: flex;
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden scroll; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    justify-content: center;
    align-items: center;
}

.portfolio-project-modal-name {
    padding: 20px 0px;
    font-size: x-large;
}

.portfolio-project-tile-description {
    margin: auto;
    padding: 20px 0px;
    font-size: 1rem;
    white-space: pre-line;
}

.portfolio-project-tile-description-wrapper {
    overflow: scroll;
    flex: 1 0 auto;
}

.portfolio-project-tile-description-wrapper::-webkit-scrollbar {
    display: none;
}

.portfolio-project-modal-links a:link {
    text-decoration: none;
}

.portfolio-project-modal-links a,
.portfolio-project-modal-links a:visited {
    color: rgb(31, 31, 31);
}

.portfolio-project-modal-links a:active,
.portfolio-project-modal-links a:hover {
    color: rgb(128, 128, 128);
}

.portfolio-project-modal-links-wrapper {
    display: inline-flex;
}

.portfolio-project-modal-links-wrapper > * {
    padding: 10px;
}

.portfolio-project-modal-content-wrapper {
    background-color: #ffffffb8;
    width: 80%;
    height: 80%;
    position: relative;
    z-index: 100;
    border-radius: 10px;
    overflow: hidden;
}

/* Modal Content */
.portfolio-project-modal-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
    overflow: scroll;
    padding: 0px 25px;
}

/* The Close Button */
.portfolio-project-modal-close {
    color: rgba(49, 49, 49, 0.502);
    font-weight: bold;
    z-index: 2;
    position: absolute;
    right: 15px;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.portfolio-project-modal-close div {
    display: flex;
    height: 20px;
    line-height: 11px;
    font-size: xxx-large;
}

.portfolio-project-modal-close:hover,
.portfolio-project-modal-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.portfolio-project-modal-hidden {
    display: none;
}

.portfolio-project-modal-content-hero-image {
    object-fit: cover;
    width: 90%;
    height: auto;
    transition: width 0.5s ease-in-out, height 1s ease-in-out;
    border-radius: 10px;
}

@media only screen and (min-width: 800px) {
    .portfolio-project-modal-content-hero-image {
        max-width: 730px;
    }

    .portfolio-project-modal-links-wrapper {
        height: fit-content;
    }
}

@media only screen and (max-width: 420px) {
    .portfolio-project-modal-content-hero-image {
        max-width: 730px;
    }

    .portfolio-project-modal-name {
        font-size: x-large;
    }
}
