.portfolio-content {
    text-align: center;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
}

.portfolio-content-no-scroll {
    text-align: center;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.portfolio-project-tile {
    height: 400px;
    width: 400px;
    overflow: hidden;
    margin: 20px;
    background-color: var(--main-bg);
    border-radius: 10px;
    padding: 15px;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
}

.portfolio-project-tile:hover,
.portfolio-project-tile:focus {
    background-color: #ffffff80;
    text-decoration: none;
    cursor: pointer;
}

.portfolio-project-tile-name {
    margin: 10px 0px;
}

.portfolio-project-tile img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    border-radius: 10px;
}

@media only screen and (max-width: 420px) {
    .portfolio-content {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
        width: 100%;
        padding: 0px 10px 0px 10px;
    }

    .portfolio-project-tile {
        width: 100%;
        text-align: center;
        margin: 10px;
        border-width: 3px;
        border-color: var(--main-bg);
        border-radius: 10px;
        font-size: 1.2rem;
        font-weight: bolder;
    }
}
